import BaseList, { IListState, Props } from '../BaseList';
import { IApplicationState } from '../../redux/reducers';
import { setShowLoading } from '../../redux/actions/actions';
import { connect } from 'react-redux';
import React, { ReactNode } from 'react';
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
} from '@material-ui/core';
import SortMenu from '../../components/SortMenu/SortMenu';
import { IReport } from '../../models/report';
import { PersonOutlineOutlined, Close } from '@material-ui/icons';
import moment from 'moment';
import { IUser, User } from '../../models/user';
import { ApiService } from '../../services';
import { UiHelper } from '../../helpers/ui-helper';
import { QueryParams } from '../../constants/query-params';

interface IReportsState extends IListState {
  showDetail: boolean;
  reportSelected?: IReport;
}

class Reports extends BaseList<Props, IReportsState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ...this.state,

      showDetail: false,
      reportSelected: undefined,
    };
  }

  render() {
    return (
      <div className="dv-container px-4 py-3 text-sm page-posts">
        {/* header */}
        <div className="flex justify-between items-center">
          {/* title */}
          <div className="pt-2 pb-4 txt-primary text-2xl font-bold">Reported Abuse</div>

          {/* sort */}
          <SortMenu
            sort={this.getSortBy()}
            sortItems={[{ value: 'createdAt', label: 'Date Reported' }]}
            onSort={this.onSortBy.bind(this)}
          />
        </div>

        {/* list */}
        {this.renderList('No abuse reports yet')}

        {this.renderDetailDialog()}
      </div>
    );
  }

  renderDetailDialog() {
    const { reportSelected } = this.state;
    if (!reportSelected) {
      return null;
    }

    const userReported = new User(reportSelected.user as IUser);

    return (
      <Dialog
        open={this.state.showDetail}
        onClose={() => this.onCloseDialog()}
        aria-labelledby="report-dialog-title"
        aria-describedby="report-dialog-description"
      >
        <DialogTitle>
          <h6>{reportSelected?.type}</h6>
          <IconButton
            className="absolute right-2 top-2"
            aria-label="close"
            onClick={() => this.onCloseDialog()}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="whitespace-pre-line">{reportSelected?.content}</div>
        </DialogContent>

        <DialogActions className="flex justify-between px-6 py-4">
          {/* user */}
          <div className="flex items-center">
            <Avatar className="mr-2 w-6 h-6 bg-gray-300" src={userReported.photoUrl()}>
              <PersonOutlineOutlined />
            </Avatar>
            {/* user name */}
            <span className="txt-primary text-sm">{userReported.data.name}</span>
          </div>

          {/* date */}
          <div className="text-gray-400 text-sm">
            {moment(reportSelected.createdAt).format('MM/DD/yyyy HH:mm:ss')}
          </div>
        </DialogActions>
      </Dialog>
    );
  }

  renderListCore(): ReactNode {
    return <List>{this.state.items.map((item: IReport, i) => this.renderItem(item, i))}</List>;
  }

  renderItem(item: IReport, i: number) {
    const userReported = new User(item.user as IUser);

    return (
      <ListItem
        className="flex flex-col py-3 px-2 items-start border-b text-base hover:bg-blue-100"
        key={i.toString()}
      >
        {/* content */}
        <div
          className="dv-txt-oneline cursor-pointer"
          onClick={() => this.onClickItem(item)}
          onKeyDown={(e) => console.log(e.nativeEvent.key)}
          tabIndex={0}
          role="button"
        >
          {item.content}
        </div>
        {/* type */}
        <div className="text-gray-500 text-sm">{item.type}</div>
        {/* user */}
        <div className="flex items-center mt-2">
          <Avatar className="mr-2 w-8 h-8 bg-gray-300" src={userReported.photoUrl()}>
            <PersonOutlineOutlined />
          </Avatar>
          {/* user name */}
          <span className="txt-primary">{userReported.data.name}</span>
        </div>

        {/* date */}
        <div className="absolute bottom-2 right-4 text-gray-400 text-sm">
          {moment(item.createdAt).format('MM/DD/yyyy HH:mm:ss')}
        </div>
      </ListItem>
    );
  }

  async loadData() {
    this.props.setShowLoading(true);

    const params = new URLSearchParams(this.props?.location.search);

    try {
      const res = await ApiService.getReports(
        (this.state.page - 1) * this.pageSize,
        this.pageSize,
        UiHelper.sortOptionString(this.getSortBy()),
        params.get(QueryParams.SEARCH),
      );

      this.setState({
        totalCount: res.count,
        items: res.reports as IReport[],
      });
    } catch (e) {
      console.log(e);

      // show error
      this.setState({ errorMsg: e.message });
    }

    this.props.setShowLoading(false);
  }

  onClickItem(item: IReport) {
    // show dialog for detail
    this.setState({
      showDetail: true,
      reportSelected: item,
    });
  }

  onCloseDialog() {
    this.setState({ showDetail: false });
  }
}

const mapStateToProps = (state: IApplicationState) => state;

const mapDispatchToProps = {
  setShowLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
