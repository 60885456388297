import moment from 'moment';
import { IconButton, Switch, TableCell, TableRow, Button, Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { queryString, removeLeadingSlash } from '../../helpers/utils';
import ROUTES from '../../constants/routes';
import { IApplicationState } from '../../redux/reducers';
import { setShowLoading, setUserSelected } from '../../redux/actions/actions';
import { connect } from 'react-redux';
import { ApiService } from '../../services';
import { IUser } from '../../models/user';
import BaseUsers, { IUsersState, Props } from './BaseUsers';
import { QueryParams } from '../../constants/query-params';
import EnableUploadButton from '../../components/EnableUploadButton/EnableUploadButton';
import LikeButton from '../../components/LikeButton/LikeButton';
import { UiHelper } from '../../helpers/ui-helper';
import SortMenu from '../../components/SortMenu/SortMenu';
import './styles.scss';

class Users extends BaseUsers<Props, IUsersState> {
  render() {
    return (
      <div className="dv-container px-4 py-3 text-sm page-users">
        {/* header */}
        <div className="flex justify-between items-center">
          {/* title */}
          <div className="pt-2 pb-4 txt-primary text-2xl font-bold">All Users</div>

          {/* sort */}
          <SortMenu
            sort={this.getSortBy()}
            sortItems={[
              { value: 'createdAt', label: 'Date Created' },
              { value: 'lastActiveAt', label: 'Date Active' },
              { value: 'postsCount', label: 'Upload Count' },
            ]}
            onSort={this.onSortBy.bind(this)}
          />
        </div>

        {/* list */}
        {this.renderList('No users added yet')}

        {this.renderDeleteConfirmDialog()}
        {this.renderDeletePostsConfirmDialog()}
      </div>
    );
  }

  renderItem(item: IUser, i: number) {
    return (
      <TableRow key={i.toString()}>
        {/* user info */}
        <TableCell className="border-0">{this.renderUserItem(item)}</TableCell>

        {/* ban */}
        <TableCell className="border-0">
          <div className="flex items-center text-gray-500">
            <span>Ban User</span>
            <Switch
              color="secondary"
              size="small"
              className="ml-2"
              checked={!!item.bannedAt}
              onChange={(e) => {
                this.onBanUser(i, e.target.checked);
              }}
            />
          </div>
        </TableCell>

        {/* delete */}
        <TableCell className="border-0">
          <div className="flex items-center text-gray-500">
            <Link
              to={{
                pathname: ROUTES.IMAGES,
                search: `?${queryString({
                  user: item._id,
                  from: removeLeadingSlash(ROUTES.USERS),
                })}`,
              }}
            >
              <Button
                color="primary"
                className="self-start outline-none normal-case text-blue-450 w-24"
                size="small"
              >
                {item.postsCount} Uploads
              </Button>
            </Link>
            <Tooltip title="Delete Uploads" placement="top">
              <IconButton
                aria-label="delete"
                className="outline-none"
                onClick={() => this.setState({ userIdDeletePosts: item._id })}
              >
                <Delete fontSize="small" className="text-gray-400" />
              </IconButton>
            </Tooltip>
          </div>
        </TableCell>

        {/* last active */}
        <TableCell className="border-0 txt-primary-light text-sm">
          {item.lastActiveAt ? moment(item.lastActiveAt).format('MM/DD/yyyy HH:mm:ss') : ''}
        </TableCell>

        {/* actions */}
        <TableCell className="border-0 w-32">
          <div className="flex">
            {/* bypass approval */}
            <LikeButton liked={!item.needApproval} onClick={() => this.onBypassApproval(i)} />

            {/* enable/disable upload */}
            <EnableUploadButton
              enabled={item.uploadEnabled}
              onClick={() => this.enableUserUpload(i, !item.uploadEnabled)}
            />

            {/* delete user */}
            <Tooltip title="Delete User" placement="top">
              <IconButton
                aria-label="delete"
                className="outline-none"
                onClick={() => this.setState({ userIdDelete: item._id })}
              >
                <Delete fontSize="small" className="text-red-400" />
              </IconButton>
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
    );
  }

  async loadData() {
    this.props.setShowLoading(true);

    const params = new URLSearchParams(this.props?.location.search);

    try {
      const res = await ApiService.getUsers(
        (this.state.page - 1) * this.pageSize,
        this.pageSize,
        UiHelper.sortOptionString(this.getSortBy()),
        params.get(QueryParams.SEARCH),
      );

      this.setState({
        totalCount: res.count,
        items: res.users as IUser[],
      });
    } catch (e) {
      console.log(e);

      // show error
      this.setState({ errorMsg: e.message });
    }

    this.props.setShowLoading(false);
  }

  onClickUser(user: IUser): void {
    super.onClickUser(user);

    // go to user detail page
    this.props.history.push({
      pathname: `${ROUTES.USERS}/${user._id}`,
      search: `?${queryString({ from: removeLeadingSlash(ROUTES.USERS) })}`,
    });
  }
}

const mapStateToProps = (state: IApplicationState) => state;

const mapDispatchToProps = {
  setShowLoading,
  setUserSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
