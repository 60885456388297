import { ChangeEvent, Component, FormEvent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Input } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AddAPhoto } from '@material-ui/icons';

import { IApplicationState } from '../../redux/reducers';
import { setShowLoading } from '../../redux/actions/actions';
import TagInput from '../../components/TagInput/TagInput';
import { ApiService } from '../../services';
import { Post } from '../../models/post';
import ROUTES from '../../constants/routes';

interface IUploadPostState {
  errorMsg: string;

  title: string;
  tags: string[];
  tagsAll: string[];
  fileSelected?: File;
}

class UploadPost extends Component<Props, IUploadPostState> {
  inputFile: HTMLInputElement | null = null;
  form: HTMLFormElement | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      // ui
      errorMsg: '',

      // data
      title: '',
      tags: [],
      tagsAll: [],
    };
  }

  async componentDidMount(): Promise<void> {
    this.props.setShowLoading(true);

    try {
      // load tags
      const tagsAll = await ApiService.getTags();
      this.setState({ tagsAll });
    } catch (e) {
      console.log(e);

      // show error
      this.setState({
        errorMsg: e.message,
      });
    }

    this.props.setShowLoading(false);
  }

  render(): ReactElement {
    const { fileSelected, tags, tagsAll, title } = this.state;
    const { user } = this.props.userReducer;

    return (
      <div className="dv-container px-4 py-3 text-sm page-post-detail">
        {/* header */}
        <div className="flex">
          {/* title */}
          <div className="pt-2 pb-4 txt-primary text-2xl font-bold">Upload New Image</div>
        </div>

        <div className="py-4 px-6 bg-light-transparent rounded-lg dv-table-main">
          {/* error notice */}
          {this.state.errorMsg ? (
            <Alert
              severity="error"
              className="mb-4"
              onClose={() => this.setState({ errorMsg: '' })}
            >
              {this.state.errorMsg}
            </Alert>
          ) : null}

          <h6 className="font-semibold text-base">Image Information</h6>

          <form
            className="flex my-4"
            onSubmit={(e) => this.handleSubmit(e)}
            ref={(ref) => {
              this.form = ref;
            }}
          >
            <input
              accept="image/*"
              className="hidden"
              id="input-image"
              type="file"
              ref={(ref) => (this.inputFile = ref)}
              onChange={this.onChangeFile.bind(this)}
            />

            {/* image */}
            <Button
              className="avatar-img bg-gray-200 mr-4 cursor-pointer text-white"
              onClick={() => this.inputFile?.click()}
            >
              {fileSelected ? (
                <img src={URL.createObjectURL(fileSelected)} alt="post" />
              ) : (
                <AddAPhoto className="text-8xl" />
              )}
            </Button>

            {/* info */}
            <div className="flex flex-col flex-1 text-sm text-gray-800">
              {/* title */}
              <div className="flex mb-3 items-center">
                <div className="w-24 text-gray-600">Title:</div>
                <Input
                  className="flex-1 text-sm"
                  placeholder="Input Title"
                  required
                  value={title}
                  onChange={(e) => {
                    this.setState({ title: e.target.value });
                  }}
                />
              </div>

              {/* author */}
              <div className="flex mb-3 items-center">
                <div className="w-24 text-gray-600">Author:</div>
                <Input
                  className="flex-1 text-sm"
                  defaultValue={user.name ?? 'Admin'}
                  disabled={true}
                />
              </div>

              {/* tags */}
              <div className="flex">
                <div className="w-24 text-gray-600 mt-1">Tags:</div>

                <TagInput
                  tags={tags}
                  tagsAll={tagsAll}
                  onChangeTags={(tagsUpdated) => this.setState({ tags: tagsUpdated })}
                />
              </div>

              {/* actions */}
              <div className="mt-8 ml-24">
                <Button
                  variant="contained"
                  color="primary"
                  className="outline-none normal-case px-8"
                  type="submit"
                  // onClick={() => {
                  //   // this.form?.dispatchEvent(new Event('submit', { cancelable: true }));
                  //   this.form?.submit();
                  // }}
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  onChangeFile(event: ChangeEvent<HTMLInputElement>) {
    event.stopPropagation();
    event.preventDefault();

    const { files } = event.target;
    if (files && files[0]) {
      console.log(files[0]);
      this.setState({ fileSelected: files[0] });
    }
  }

  async handleSubmit(e: FormEvent) {
    e.stopPropagation();
    e.preventDefault();

    const { fileSelected, title, tags } = this.state;

    // check validity
    if (!fileSelected) {
      this.setState({ errorMsg: 'Image should be selected' });
      return;
    }

    this.props.setShowLoading(true);

    try {
      // upload image
      const imgFileName = await ApiService.uploadFile(fileSelected, `${Post.IMAGE_PATH}`);

      // create post
      await ApiService.createPost({ title, imgFileName, tags });

      // redirect to images page
      this.props.history.replace({
        pathname: ROUTES.IMAGES_APPROVED,
      });
    } catch (e) {
      console.log(e);

      this.setState({ errorMsg: e.message });
    }

    this.props.setShowLoading(false);
  }
}

interface PropsFromDispatch {
  setShowLoading: typeof setShowLoading;
}

interface Props extends IApplicationState, PropsFromDispatch, RouteComponentProps {}

const mapStateToProps = (state: IApplicationState) => state;
const mapDispatchToProps = {
  setShowLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadPost);
