const ROUTES = {
  LOGIN: '/login',
  SIGNUP: '/signup',
  USERS: '/users',
  USERS_BANNED: '/banned-users',
  USERS_DELETED: '/deleted-users',
  USERS_DOWNLOADED_POST: '/downloaded-users',
  CREATE_IMAGE: '/create-image',
  IMAGES: '/images',
  IMAGES_PENDING: '/images-pending-approval',
  IMAGES_APPROVED: '/images-approved',
  SETTINGS: '/settings',
  REPORTS: '/reports',
  REPORTS_IMAGE: '/reports/image',
  REPORTS_ABUSE: '/reports/abuse',
  REPORTS_USER: '/reports/user',

  POLICY: '/policy',
  SUPPORT: '/support',
};

export default ROUTES;
