import { ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  Tooltip,
} from '@material-ui/core';
import { ThumbUpAltOutlined, ThumbUpAltTwoTone } from '@material-ui/icons';
import Lightbox from 'react-image-lightbox';

import BaseList, { IListState, Props } from '../BaseList';
import { ApiService } from '../../services';
import { IPost, Post } from '../../models/post';
export interface IPostsState extends IListState {
  postIdDelete: string;
  postIdModal: string;
}

export default class BasePosts<P extends Props, S extends IPostsState> extends BaseList<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      ...this.state,
      postIdDelete: '',
      postIdModal: '',
    };
  }

  renderListCore(): ReactNode {
    return <List>{this.state.items.map((item: IPost, i) => this.renderItem(item, i))}</List>;
  }

  renderDeleteConfirmDialog(): ReactNode {
    return (
      <Dialog
        open={!!this.state.postIdDelete}
        onClose={() => this.onCloseDialog()}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Are you sure you want to delete this image?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The image will be deleted permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.onDeleteImage()} color="primary">
            Delete
          </Button>
          <Button onClick={() => this.onCloseDialog()} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderImageModal(): ReactNode {
    const item = this.state.items.find((p) => p._id === this.state.postIdModal) as IPost;
    if (item) {
      return (
        <Lightbox
          mainSrc={new Post(item).imageUrl()}
          onCloseRequest={() => this.setState({ postIdModal: '' })}
        />
      );
    }

    return null;
  }

  renderItemFooter(item: IPost, index: number): ReactNode {
    return (
      <div className="flex items-center self-end">
        <div className="text-right text-gray-600 pr-4">{item.downloadCount} Downloads</div>

        {/* recommend */}
        <Tooltip title={item.recommendedAt ? 'Cancel Recommend' : 'Recommend'} placement="bottom">
          <IconButton
            aria-label="delete"
            className="outline-none ml-2"
            onClick={(e) => {
              e.stopPropagation();

              this.onRecommendItem(index);
            }}
          >
            {item.recommendedAt ? (
              <ThumbUpAltTwoTone fontSize="small" className="text-red-400" />
            ) : (
              <ThumbUpAltOutlined fontSize="small" className="text-gray-400" />
            )}
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  /**
   * close confirm dialog
   */
  onCloseDialog(): void {
    this.setState({
      postIdDelete: '',
    });
  }

  async onDeleteImage(): Promise<void> {
    try {
      await ApiService.deletePost(this.state.postIdDelete);

      // refresh list
      this.loadData();
    } catch (e) {
      console.log(e);

      // show error
      this.setState({ errorMsg: e.message });
    }

    this.onCloseDialog();
  }

  onClickItem(item: IPost): void {
    this.props.setItemSelected(item);
  }

  async onRecommendItem(index: number): Promise<void> {
    const { items } = this.state;
    const isRecommend = !items[index].recommendedAt;

    items[index].recommendedAt = isRecommend ? new Date() : undefined;
    this.setState({ items });

    // save to db
    this.props.setShowLoading(true);

    try {
      await ApiService.recommendPost(items[index]._id, isRecommend);
    } catch (e) {
      console.log(e);

      // revert
      items[index].recommendedAt = isRecommend ? undefined : new Date();

      // show error
      this.setState({ items, errorMsg: e.message });
    }

    this.props.setShowLoading(false);
  }
}
